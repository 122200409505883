import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  pieChartLegendWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  engagement: {
    overflowX: "auto",
  },
  engagementSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  engagementSelect: {
    padding: 10,
    paddingRight: 25,
  },
  percentBox: {},
  percentTitle: {
    // color: "#1b5e20",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  percentValue: {
    fontSize: 40,
    fontWeight: "bold",
  },
  engagementTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
}));
