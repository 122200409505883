import { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Hotel, Update } from "@material-ui/icons";

import classNames from "classnames";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

import Avatar from "@material-ui/core/Avatar";

// data
import {
  updateUser,
  getUser,
  getCompanies,
  getOrganizations,
  getUserActivity,
} from "../../hooks/useRequest";

// context
import { useUserState } from "../../context/UserContext";

// helpers
import {
  roles,
  roleLabel,
  nameInitials,
  companyName,
  isAdmin,
} from "../../helpers/utils";
import { USER_STATUS } from "../../helpers/constants";
import { iUser, iUserErrors, iDisplay } from "../../helpers/props";

// styles
import useStyles from "./styles";

// dummy data
import { quiz, docs } from "./data";

export default function User() {
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams();

  // get role of the login user
  const {
    userData: { role },
  } = useUserState();

  const [activity, setActivity] = useState([]);
  const [user, setUser] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iUser
  );
  const [data, setData] = useState([]);
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iUserErrors
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );

  const [companies, setCompanies] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const handleUpdate = async () => {
    setDisplay({
      loading: true,
    });
    await updateUser(id, data);
    setDisplay({
      loading: false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setUser({
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const data = await getCompanies();
      setCompanies(data);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const data = await getOrganizations();
      setOrganizations(data);
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUser(id);
      setUser(data);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getUserActivity(id);
      setActivity(data);
    };

    fetchData();
  }, [id]);

  const company_id = data?.company || user?.company?._id || "";

  console.log("USER :====>", user?.organization?._id);
  console.log("ORGS :====>", organizations);

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Widget>
          <div className={classes.account}>
            <Avatar className={classes.large}>
              {nameInitials(user?.first_name, user?.last_name)}
            </Avatar>
            <Typography variant="h3" className={classes.text}>
              {user?.first_name} {user?.last_name}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {companyName(companies, company_id)}
            </Typography>
            <Typography className={classNames(classes.text, classes.mb2)}>
              {roleLabel(user.role)}
            </Typography>
          </div>
        </Widget>
      </Grid>
      <Grid item xs={8}>
        <Widget>
          <Typography variant="h3" className={classes.text}>
            Profile
          </Typography>
          <Divider />
          <Grid container spacing={4} style={{ paddingTop: 20 }}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  value={user.first_name}
                  required
                  error={errors.first_name}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  value={user.last_name}
                  required
                  error={errors.last_name}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl
                className={classes.formControl}
                required
                error={errors.role}
              >
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  name="role"
                  value={user.role}
                  onChange={handleChange}
                >
                  {roles.map((role, key) => (
                    <MenuItem value={role.value} key={key}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl
                className={classes.formControl}
                required
                error={errors.company}
              >
                <InputLabel id="company-label">Company</InputLabel>
                <Select
                  labelId="company-label"
                  id="company"
                  name="company"
                  value={company_id}
                  required
                  error={errors.company}
                  onChange={handleChange}
                  disabled={isAdmin(role)}
                >
                  {companies.map(({ _id, company_name }, key) => (
                    <MenuItem value={_id} key={key}>
                      {company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="organization-label">Partner Account</InputLabel>
                <Select
                  labelId="organization-label"
                  id="organization"
                  name="organization"
                  value={user?.organization?._id || ""}
                  onChange={handleChange}
                >
                  {organizations.map(({ _id, organization_name }, key) => (
                    <MenuItem value={_id} key={key}>
                      {organization_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={user.email}
                  disabled={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  value=""
                  type="password"
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  name="status"
                  value={user.status || 0}
                  onChange={handleChange}
                >
                  {USER_STATUS.map(({ name, value }, key) => (
                    <MenuItem value={value} key={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {display.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  endIcon={<Update />}
                >
                  Update
                </Button>
              )}
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      <Grid item xs={4}>
        <Widget title="User Activity" disableWidgetMenu>
          {activity.length ? (
            <Stepper orientation="vertical">
              {activity.map((log, key) => (
                <Step key={key} expanded={true}>
                  <StepLabel>{log.screen}</StepLabel>
                  <StepContent>
                    <Typography>{log.action}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Hotel style={{ fontSize: 58 }} />
              <span style={{ fontSize: "1.2rem" }}>No Activity</span>
            </div>
          )}
        </Widget>
      </Grid>
      <Grid item xs={4}>
        <Widget title="Documents" disableWidgetMenu>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Status</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Bank Check
                </TableCell>
                <TableCell align="right">
                  {user.is_bank_check ? "Verified" : "Unverified"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Right To Work
                </TableCell>
                <TableCell align="right">
                  {user.is_right_to_work ? "Verified" : "Unverified"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Login Count
                </TableCell>
                <TableCell align="right">{user.login_count}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Quiz Attempt
                </TableCell>
                <TableCell align="right">{user.quiz_attempt_count}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Video Views
                </TableCell>
                <TableCell align="right">{user.video_view_count}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Widget>
      </Grid>
      <Grid item xs={4}>
        <Widget title="Quiz" disableWidgetMenu>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Score</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quiz.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{row.score}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Widget>
      </Grid>
    </Grid>
  );
}
