import { Switch } from "@material-ui/core";

export const docs = [
  // {
  //   id: 0,
  //   title: "Identification Documents",
  //   status: (
  //     <Switch
  //       checked={false}
  //       onChange={() => console.log("checked")}
  //       name="checkedA"
  //       inputProps={{ "aria-label": "secondary checkbox" }}
  //     />
  //   ),
  // },
  // {
  //   id: 1,
  //   title: "Educational Certificates",
  //   status: (
  //     <Switch
  //       checked={true}
  //       onChange={() => console.log("checked")}
  //       color="primary"
  //       name="checkedB"
  //       inputProps={{ "aria-label": "primary checkbox" }}
  //     />
  //   ),
  // },
  // {
  //   id: 2,
  //   title: "Work Authorization Documents",
  //   status: (
  //     <Switch
  //       checked={false}
  //       onChange={() => console.log("checked")}
  //       name="checkedA"
  //       inputProps={{ "aria-label": "secondary checkbox" }}
  //     />
  //   ),
  // },
  // {
  //   id: 3,
  //   title: "Bank Account Information",
  //   status: (
  //     <Switch
  //       checked={false}
  //       onChange={() => console.log("checked")}
  //       name="checkedA"
  //       inputProps={{ "aria-label": "secondary checkbox" }}
  //     />
  //   ),
  // },
  // {
  //   id: 4,
  //   title: "Tax Forms",
  //   status: (
  //     <Switch
  //       checked={false}
  //       onChange={() => console.log("checked")}
  //       name="checkedA"
  //       inputProps={{ "aria-label": "secondary checkbox" }}
  //     />
  //   ),
  // },
];

export const stats = [
  { name: "Primary", value: 50, color: "primary" },
  { name: "Secondary", value: 50, color: "secondary" },
];
