import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  engagementSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  engagementSelect: {
    padding: 10,
    paddingRight: 25,
  },
  download: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  downloadIcon: {
    color: "#080836",
    fontSize: 48,
  },
}));
