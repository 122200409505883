import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Business, Person, Update } from "@material-ui/icons";

// components
import Widget from "../../components/Widget/Widget";

// helpers
import { iOrganization, iDisplay } from "../../helpers/props";
import { colors } from "../../helpers/utils";

// styles
import useStyles from "./styles";

// data
import {
  getOrganization,
  getCompanies,
  updateOrganization,
} from "../../hooks/useRequest";

export default function Partner() {
  const classes = useStyles();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [partner, setPartner] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iOrganization
  );
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { company_name: false }
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );

  const handleUpdate = async () => {
    setDisplay({
      loading: true,
    });

    const { _id } = partner;
    await updateOrganization(_id, data);
    setDisplay({
      loading: false,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // values for update
    setData({
      ...data,
      [name]: value,
    });
    // values for display
    setPartner({
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const companies = await getCompanies();
      setCompanies(companies);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOrganization(id);
      setPartner(data);
    };

    if (!display.loading) {
      fetchData();
    }
  }, [id, display]);

  const company = companies?.find(({ _id }) => partner?.company === _id);

  // console.log("PARTNER :======>", partner);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Widget>
          {/* <div className={classes.logoWrapper}>
            {company?.company_logo ? (
              <img
                src={company?.company_logo}
                alt={company?.company_name}
                className={classes.logo}
              />
            ) : (
              <Typography className={classes.noLogo}>LOGO HERE</Typography>
            )}
          </div>
          <Upload onUpload={handleUploadLogo} loading={display.loading} />
          <Divider className={classes.mb5} /> */}
          <h3 className={classes.header}>
            Parent Company <Business className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                name="company"
                // label="Partner Account Name"
                value={company?.company_name}
                // required
                // error={errors.company_name}
                onChange={handleChange}
                disabled
              />
            </FormControl>
          </div>
          <h3 className={classes.header}>
            Account Name <Business className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                name="account_name"
                // label="Partner Account Name"
                value={partner?.organization_name}
                // required
                // error={errors.company_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <h3 className={classes.header}>
            App Name <Business className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                name="app_name"
                // label="Partner Account Name"
                value={partner?.app_name}
                // required
                // error={errors.company_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          {/* <h3 className={classes.header}>
            Address <LocationOn className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="street"
                name="address_street"
                label="Street"
                value={partner?.address_street}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="city"
                    name="address_city"
                    label="City"
                    value={partner.address_city}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="zip"
                    name="address_zip"
                    label="Zip"
                    value={partner.address_zip}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div> */}
          <h3 className={classes.header}>
            Contact <Person className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="person"
                name="organization_contact_person"
                label="Name"
                value={partner.organization_contact_person}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="email"
                    name="organization_email"
                    label="Email"
                    type="email"
                    value={partner.organization_email}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="phone"
                    name="organization_contact_number"
                    label="Phone"
                    value={partner.organization_contact_number}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            {display.loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                endIcon={<Update />}
                onClick={handleUpdate}
              >
                Update
              </Button>
            )}
          </div>
        </Widget>
      </Grid>
      <Grid item xs={8}>
        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget title="Color Scheme">
              <Grid container direction="row" spacing={4}>
                {colors.map((color, key) => (
                  <Preview
                    company={company}
                    color={color}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    key={key}
                  />
                ))}
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget title="Intro Video">
              {company?.intro_video && (
                <ReactPlayer
                  url={company?.intro_video}
                  playing={true}
                  controls={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  width="100%"
                />
              )}
              <div className={classes.btnWapper}>
                <input
                  accept="image/*"
                  id="intro-video-upload"
                  type="file"
                  onChange={handleUploadVideo}
                  hidden
                />
                <label htmlFor="intro-video-upload">
                  {display.loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<VideoCall />}
                    >
                      Upload Intro Video
                    </Button>
                  )}
                </label>
              </div>
            </Widget>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
