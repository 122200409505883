import { Grid } from "@material-ui/core";

// styles
import useStyles from "../styles";

// components
import Widget from "../../../components/Widget";
import EngagementChart from "./Engagement/Chart";
import EngagementList from "./Engagement/List";

const EngagementContainer = ({ data, title }) => {
  const classes = useStyles();
  return (
    <Widget bodyClass={classes.engagement} title={title}>
      <Grid container spacing={4}>
        <Grid item md={7} sm={12}>
          <EngagementChart data={data} />
        </Grid>
        <Grid item md={5} sm={12}>
          <EngagementList data={data} />
        </Grid>
      </Grid>
    </Widget>
  );
};

export default EngagementContainer;
