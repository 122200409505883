import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from "@material-ui/core";
import Icon from "@mdi/react";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
// admin user list
import Users from "../../pages/users";
import User from "../../pages/users/User";

import Companies from "../../pages/companies";
import Company from "../../pages/companies/Company";
import CompanyAdmins from "../../pages/companies/Admins";
import Settings from "../../pages/companies/Settings";

import Organizations from "../../pages/organizations";
import Partner from "../../pages/organizations/Partner";
import PartnerUsers from "../../pages/organizations/Users";

import Quiz from "../../pages/quiz";
import QuizDetails from "../../pages/quiz/QuizDetails";
import Questions from "../../pages/questions";
import IdeasHub from "../../pages/ideashub";

import Reports from "../../pages/reports";
import Support from "../../pages/tables/Support";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Switch>
          <Route path="/app/dashboard" component={Dashboard} />

          {/* COMPANY ROUTES */}
          <Route path="/app/companies" component={Companies} />
          <Route path="/app/company/:id" component={Company} />
          <Route path="/app/company-admins/:id" component={CompanyAdmins} />

          {/* USER ROUTE */}
          <Route path="/app/user/:id" component={User} />
          {/* admin only route */}
          <Route path="/app/users" component={Users} />

          {/* ORGANIZATION/PARTNER ACCOUNT ROUTES */}
          <Route path="/app/partners" component={Organizations} />
          <Route path="/app/partner/:id" component={Partner} />
          <Route path="/app/partner-users/:id" component={PartnerUsers} />

          {/* QUIZ ROUTE */}
          <Route path="/app/quizzes" component={Quiz} />
          <Route path="/app/quiz/:id" component={QuizDetails} />

          {/* QUESTIONS ROUTE */}
          <Route path="/app/questions" component={Questions} />

          {/* IDEAS HUB ROUTE */}
          <Route path="/app/ideas" component={IdeasHub} />

          {/* MISC ROUTE */}
          <Route path="/app/settings" component={Settings} />
          <Route path="/app/reports" component={Reports} />
          <Route path="/app/support" component={Support} />
        </Switch>
        <Box
          mt={5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <div>
            <Link
              color={"primary"}
              href={"#/"}
              target={"_blank"}
              className={classes.link}
            >
              EmproTech
            </Link>
            <Link
              color={"primary"}
              href={"#"}
              target={"_blank"}
              className={classes.link}
            >
              About Us
            </Link>
            <Link
              color={"primary"}
              href={"#"}
              target={"_blank"}
              className={classes.link}
            >
              Blog
            </Link>
          </div>
          <div>
            <Link href={"#"} target={"_blank"}>
              <IconButton aria-label="facebook">
                <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
            <Link href={"#"} target={"_blank"}>
              <IconButton aria-label="twitter">
                <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
            <Link href={"#"} target={"_blank"}>
              <IconButton aria-label="github" style={{ marginRight: -12 }}>
                <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default withRouter(Layout);
