import tinycolor from "tinycolor2";

const primary = "#080836";
const secondary = "#551C4C";
const tertiary = "#D23D70";
const neutral = "#F5F5F5";
const overlay = "#EAA2FF";
const accent = "#CB6CE6";

const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: tertiary,
      light: tinycolor(tertiary).lighten(lightenRate).toHexString(),
      dark: tinycolor(tertiary).darken(darkenRate).toHexString(),
    },
    neutral: {
      main: neutral,
      light: tinycolor(neutral).lighten(lightenRate).toHexString(),
      dark: tinycolor(neutral).darken(darkenRate).toHexString(),
    },
    overlay: {
      main: overlay,
      light: tinycolor(overlay).lighten(lightenRate).toHexString(),
      dark: tinycolor(overlay).darken(darkenRate).toHexString(),
    },
    accent: {
      main: accent,
      light: tinycolor(accent).lighten(lightenRate).toHexString(),
      dark: tinycolor(accent).darken(darkenRate).toHexString(),
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
    danger: {
      main: "#f44336",
      light: tinycolor("#ffebee").lighten(lightenRate).toHexString(),
      dark: tinycolor("#b71c1c").darken(darkenRate).toHexString(),
    },
    purple: {
      main: "#9c27b0",
      light: tinycolor("#9c27b0").lighten(lightenRate).toHexString(),
      dark: tinycolor("#9c27b0").darken(darkenRate).toHexString(),
    },
    indigo: {
      main: "#3f51b5",
      light: tinycolor("#3f51b5").lighten(lightenRate).toHexString(),
      dark: tinycolor("#3f51b5").darken(darkenRate).toHexString(),
    },
    pink: {
      main: "#e91e63",
      light: tinycolor("#e91e63").lighten(lightenRate).toHexString(),
      dark: tinycolor("#e91e63").darken(darkenRate).toHexString(),
    },
    blue: {
      main: "#3f51b5",
      light: tinycolor("#3f51b5").lighten(lightenRate).toHexString(),
      dark: tinycolor("#3f51b5").darken(darkenRate).toHexString(),
    },
    cyan: {
      main: "#00bcd4",
      light: tinycolor("#00bcd4").lighten(lightenRate).toHexString(),
      dark: tinycolor("#00bcd4").darken(darkenRate).toHexString(),
    },
    teal: {
      main: "#009688",
      light: tinycolor("#009688").lighten(lightenRate).toHexString(),
      dark: tinycolor("#009688").darken(darkenRate).toHexString(),
    },
    green: {
      main: "#4caf50",
      light: tinycolor("#4caf50").lighten(lightenRate).toHexString(),
      dark: tinycolor("#4caf50").darken(darkenRate).toHexString(),
    },
    lime: {
      main: "#cddc39",
      light: tinycolor("#cddc39").lighten(lightenRate).toHexString(),
      dark: tinycolor("#cddc39").darken(darkenRate).toHexString(),
    },
    yellow: {
      main: "#ffeb3b",
      light: tinycolor("#ffeb3b").lighten(lightenRate).toHexString(),
      dark: tinycolor("#ffeb3b").darken(darkenRate).toHexString(),
    },
    orange: {
      main: "#ff9800",
      light: tinycolor("#ff9800").lighten(lightenRate).toHexString(),
      dark: tinycolor("#ff9800").darken(darkenRate).toHexString(),
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24,
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
  },
};

export default defaultTheme;
