import { useEffect, useReducer, useState } from "react";

import {
  ButtonGroup,
  Button,
  IconButton,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
} from "@material-ui/core";
import {
  QuestionAnswer,
  Save,
  Edit,
  Add,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import EmproPaper from "../../components/Loading/Paper";
import EmproModal from "../../components/Modal";
import EmproSnackbar from "../../components/Snackbar";
import { ConfirmDelete } from "../../components/Dialog";
import { Typography, Upload } from "../../components/Wrappers";

// data
import {
  addQuestion,
  deleteQuestion,
  getQuestions,
} from "../../hooks/useRequest";

// helpers
import { tblQuestionHdr } from "../../helpers/utils";
import { iDisplay, iQuestionErrors } from "../../helpers/props";

// styles
import useStyles from "./styles";

const getChoices = (options) => (
  <List>
    {options.map(({ text }, key) => (
      <ListItem key={key}>
        <ListItemIcon>
          {getAnswer(options) === text ? (
            <RadioButtonChecked />
          ) : (
            <RadioButtonUnchecked />
          )}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    ))}
  </List>
);

const actionButton = (id) => (
  <IconButton
    aria-label="edit"
    color="primary"
    onClick={() => console.log("Edit?")}
  >
    <Edit />
  </IconButton>
);

const getAnswer = (choices) => {
  const [answer] = choices.filter((data) => data.is_correct);
  return answer?.text;
};

export default function Questions() {
  const classes = useStyles();
  const theme = useTheme();

  const numblocks = [1, 2, 3, 4];

  const [questions, setQuestions] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [question, setQuestion] = useState("");
  const [choices, setChoices] = useState({});
  const [answer, setAnswer] = useState("");
  const [file, setFile] = useState("");

  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { iQuestionErrors }
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );

  const handleDelete = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    const promises = deleted.map(async (id) => await deleteQuestion(id));
    const results = await Promise.all(promises);

    if (results.length) {
      setResponse({
        error: results[0].error,
        message: results[0].message,
      });
    }

    setDisplay({
      loading: false,
      snackbar: true,
    });
  };

  console.log("QUESTIONS :=====>", questions);

  const handleSubmit = async () => {
    // get values from array
    const values = Object.values(choices);
    const correct = values.indexOf(answer);
    const options = values.map((option, index) => ({
      text: option,
      is_correct: index === correct,
    }));

    // const params = new FormData();
    // params.append("text", question);
    // params.append("category", "Multiple Choice");
    // params.append("options", JSON.stringify(options));
    // params.append("correct_index", correct);
    // params.append("order", 0);
    // params.append("background", file);
    // params.append("comments", "N/A");

    // setup question object
    const params = {
      text: question,
      category: "Multiple Choice",
      options: options,
      correct_index: correct,
      order: 0,
      background: "",
      comments: "N/A",
    };

    let errorCount = 0;

    // required fields
    errors["question"] = !question;
    if (!question) errorCount++;

    numblocks.forEach((num) => {
      errors[`choice${num}`] = !choices[num - 1];
      if (!choices[num - 1]) errorCount++;
    });

    errors["answer"] = !answer;
    if (!answer) errorCount++;

    setErrors(errors);

    if (errorCount) return;

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    // submit form
    setDisplay({
      modal: false,
      loading: true,
    });

    const { error, message } = await addQuestion(params);

    setResponse({
      error,
      message,
    });

    setDisplay({
      loading: false,
      snackbar: true,
    });

    // reset form
    handleCloseModal();
  };

  const handleUpload = async (e) => {
    const { files } = e.target;
    setFile(URL.createObjectURL(files[0]));
  };

  const handleQuestion = (e) => {
    const { value } = e.target;
    setQuestion(value);
  };

  const handleAnswer = (e) => {
    const { value } = e.target;
    setAnswer(value);
  };

  const handleChoices = (e, i) => {
    const { value } = e.target;
    setChoices((prev) => ({
      ...prev,
      [i]: value,
    }));
  };

  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay({
      snackbar: false,
    });
  };

  const handleCloseDialog = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleCloseModal = () => {
    // reset form
    setQuestion("");
    setAnswer("");
    setChoices({});

    setErrors(iQuestionErrors);
    setDisplay({
      modal: false,
    });
  };

  const handleOpen = () => {
    setDisplay({
      modal: true,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getQuestions();
      const questions = data.map(({ _id, text, options }) => ({
        _id,
        question: text,
        choices: getChoices(options),
        answer: getAnswer(options),
        action: actionButton,
      }));

      if (!response.error) {
        setDisplay({
          loading: false,
        });
        setQuestions(questions);
      }
    };

    setDisplay({
      loading: true,
    });
    fetchData();
  }, [response.error]);

  const options = {
    onRowsDelete: (rows) => {
      setDisplay({
        dialog: true,
      });
      const forDeletion = rows.data.map((row) => questions[row.index]["_id"]);
      setDeleted(forDeletion);
    },
  };

  return (
    <>
      <PageTitle
        title="Questions"
        button={
          <ButtonGroup>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<Add />}
              onClick={handleOpen}
            >
              Add Question
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<QuestionAnswer />}
              onClick={handleOpen}
            >
              Upload Questions
            </Button>
          </ButtonGroup>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {display.loading ? (
            <EmproPaper />
          ) : (
            <MUIDataTable
              data={questions}
              columns={tblQuestionHdr}
              options={options}
            />
          )}
        </Grid>
        <EmproModal
          open={display.modal}
          onClose={handleCloseModal}
          ariaLabel="question"
          ariaDescription="question"
        >
          <h3 className={classes.header}>
            Add Question <QuestionAnswer className={classes.iconStyle} />
          </h3>
          <div className={classes.wrapper}>
            <div className={classes.bgContainer}>
              {file ? (
                <img src={file} alt={file} className={classes.logo} />
              ) : (
                <Typography className={classes.noImg}>
                  ADD BACKGROUND
                </Typography>
              )}
            </div>
            <Upload onUpload={handleUpload} />
            <FormControl className={classes.formControl}>
              <TextField
                id="question"
                label="Question"
                required
                error={errors.question}
                onChange={handleQuestion}
              />
            </FormControl>
            {numblocks.map((val, key) => (
              <FormControl className={classes.formControl} key={key}>
                <TextField
                  id={`choice${val}`}
                  label={`Choice ${val}`}
                  required
                  error={errors[`choice${val}`]}
                  onChange={(e) => handleChoices(e, key)}
                />
              </FormControl>
            ))}
            <FormControl className={classes.formControl}>
              <TextField
                id="answer"
                label="Answer"
                required
                error={errors.answer}
                onChange={handleAnswer}
              />
            </FormControl>
          </div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              size="medium"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Save />}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </EmproModal>
      </Grid>
      <EmproSnackbar
        display={display}
        onClose={handleCloseSnackbar}
        response={response}
      />
      <ConfirmDelete
        display={display}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </>
  );
}
