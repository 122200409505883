export const quiz = [
  {
    id: 0,
    title: "Cybersecurity Awareness",
    score: "8/10",
    status: "Pass",
  },
  {
    id: 1,
    title: "Diversity and Inclusion Training",
    score: "--",
    status: "Active",
  },
  {
    id: 2,
    title: "Financial Literacy Assessment",
    score: "--",
    status: "Active",
  },
  {
    id: 3,
    title: "Environmental Sustainability",
    score: "9/10",
    status: "Pass",
  },
  {
    id: 4,
    title: "Health and Safety Compliance",
    score: "8/10",
    status: "Pass",
  },
];

export const docs = [
  {
    id: 0,
    title: "Identification Documents",
    status: "Approved",
  },
  {
    id: 1,
    title: "Educational Certificates",
    status: "Approved",
  },
  {
    id: 2,
    title: "Work Authorization Documents",
    status: "Submitted",
  },
  {
    id: 3,
    title: "Bank Account Information",
    status: "--",
  },
  {
    id: 4,
    title: "Tax Forms",
    status: "--",
  },
];

export const stats = [
  { name: "Quiz", value: 10, color: "primary" },
  { name: "Documents", value: 5, color: "secondary" },
];

export const logs = [
  {
    _id: "66d02c9aacf6c34b5a67442a",
    screen: "empro landing",
    action: "user tapped intro landing",
    status: 1,
    company: "66725e4ed3e3a4c65a532a3e",
    user: {
      _id: "66797ba0551d1e9468508584",
      first_name: "Rose",
      last_name: "Lord",
      email: "rose@emprogroup.co.uk",
    },
  },
  {
    _id: "66d02c9aacf6c34b5a67442c",
    screen: "intro landing",
    action: "user enter",
    status: 1,
    company: "66725e4ed3e3a4c65a532a3e",
    user: {
      _id: "66797ba0551d1e9468508584",
      first_name: "Rose",
      last_name: "Lord",
      email: "rose@emprogroup.co.uk",
    },
  },
  {
    _id: "66d02f2facf6c34b5a674432",
    screen: "intro landing",
    action: "user exit",
    status: 1,
    company: "66725e4ed3e3a4c65a532a3e",
    user: {
      _id: "66797ba0551d1e9468508584",
      first_name: "Rose",
      last_name: "Lord",
      email: "rose@emprogroup.co.uk",
    },
  },
  {
    _id: "66d02f33acf6c34b5a674434",
    screen: "empro landing",
    action: "user tapped settings",
    status: 1,
    company: "66725e4ed3e3a4c65a532a3e",
    user: {
      _id: "66797ba0551d1e9468508584",
      first_name: "Rose",
      last_name: "Lord",
      email: "rose@emprogroup.co.uk",
    },
  },
  {
    _id: "66d02f33acf6c34b5a674436",
    screen: "settings",
    action: "user enter",
    status: 1,
    company: "66725e4ed3e3a4c65a532a3e",
    user: {
      _id: "66797ba0551d1e9468508584",
      first_name: "Rose",
      last_name: "Lord",
      email: "rose@emprogroup.co.uk",
    },
  },
];
