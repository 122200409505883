import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const OnboardingChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={144}>
    <PieChart>
      <Pie data={data} innerRadius={40} outerRadius={65} dataKey="value">
        {data.map((data, index) => (
          <Cell key={`cell-${index}`} fill={data.color} />
        ))}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
);

export default OnboardingChart;
