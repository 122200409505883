export const colors = [
  {
    name: "primary_color",
    label: "Main Colour/Background",
    value: "",
    description: "The colour of the background of the pages in the app",
  },
  {
    name: "secondary_color",
    label: "Button 1 Background",
    value: "",
    description: "The background colour of the button",
  },
  {
    name: "tertiary_color",
    label: "Button 1 Icon & Text",
    value: "",
    description: "The colour of the graphic and text on the button",
  },
  {
    name: "neutral_color",
    label: "Button 2 Background",
    value: "",
    description: "The background colour of the button",
  },
  {
    name: "overlay_color",
    label: "Button 2 Icon & Text",
    value: "",
    description: "The colour of the graphic and text on the button",
  },
  {
    name: "accent_color",
    label: "Accent Colour",
    value: "",
    description: "Call To Action Button Background colour",
  },
];

export const roles = [
  // {
  //   name: "Super User",
  //   value: 0,
  // },
  {
    name: "Admin User",
    value: 2,
  },
  {
    name: "End User",
    value: 3,
  },
];

export const reports = [
  "Right to Work - Verified",
  "Right to work - Unverified",
  "Bank Check - Verified",
  "Bank Check - Unverified",
  "Top Login Users",
  "Top Quiz Completed",
  "Top Video Views",
];

export const roleLabel = (role) => {
  let label = "End User";
  if (role === 0) label = "Super User";
  if (role === 1 || role === 2) label = "Admin User";

  return label;
};

export const isAdmin = (role) => role === 1 || role === 2;
export const isSuper = (role) => role === 0;

export const nameInitials = (first, last) =>
  (first.charAt(0) + last.charAt(0)).toUpperCase();

export const companyName = (companies, id) => {
  const [company] = companies.filter(({ _id }) => _id === id);
  return company?.company_name;
};

export const tblUserHdr = [
  {
    name: "first_name",
    label: <strong>First Name</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "last_name",
    label: <strong>Last Name</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "email",
    label: <strong>Email</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: <strong>Parent Company</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "role",
    label: <strong>Role</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: <strong>Status</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: <strong>View</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblCompanyHdr = [
  {
    name: "company_name",
    label: <strong>Name</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "address",
    label: <strong>Address</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company_contact_person",
    label: <strong>Contact Person</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company_email",
    label: <strong>Contact Email</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company_contact_number",
    label: <strong>Contact Number</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: <strong>View</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "users",
    label: <strong>Admins</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblOrgHdr = [
  {
    name: "organization_name",
    label: <strong>Account Name</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: <strong>Parent Company</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "app_name",
    label: <strong>App Name</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: <strong>View</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "users",
    label: <strong>Users</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblQuizHdr = [
  {
    name: "title",
    label: <strong>Title</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: <strong>Company</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: <strong>Status</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "view",
    label: <strong>View</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tblQuestionHdr = [
  {
    name: "question",
    label: <strong>Question</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "choices",
    label: <strong>Choices</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "answer",
    label: <strong>Answer</strong>,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "action",
    label: <strong>Edit</strong>,
    options: {
      filter: false,
      sort: false,
    },
  },
];
