import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useTheme } from "@material-ui/styles";

const showPercentage = ({ percent }) => `${(percent * 100).toFixed(0)}%`;

const EngagementChart = ({ data }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height={320}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={35}
          outerRadius={60}
          dataKey="value"
          label={showPercentage}
        >
          {data.map((data, key) => (
            <Cell key={`cell-${key}`} fill={theme.palette[data.color].main} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default EngagementChart;
