import { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  IconButton,
} from "@material-ui/core";
import {
  Add,
  Business,
  Group,
  Person,
  LocationOn,
  Save,
} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import EmproPaper from "../../components/Loading/Paper";
import EmproModal from "../../components/Modal";
import EmproSnackbar from "../../components/Snackbar";
import { ConfirmDelete } from "../../components/Dialog";

// helpers
import { tblCompanyHdr } from "../../helpers/utils";
import { iCompany, iDisplay } from "../../helpers/props";

// styles
import useStyles from "./styles";

// data
import {
  addCompany,
  deleteCompany,
  getCompanies,
} from "../../hooks/useRequest";

export default function Companies() {
  const classes = useStyles();

  const [company, setCompany] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iCompany
  );
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { company_name: false }
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );

  const [companies, setCompanies] = useState([]);
  const [deleted, setDeleted] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompany({
      [name]: value,
    });
  };

  const handleCancel = () => {
    // reset form
    Object.keys(company).forEach((key) => (company[key] = ""));
    Object.keys(errors).forEach((key) => (errors[key] = false));

    setCompany(company);
    setErrors(errors);
    setDisplay({
      modal: false,
    });
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay({
      snackbar: false,
    });
  };

  const handleCloseDialog = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleOpen = () => {
    setDisplay({
      modal: true,
    });
  };

  const handleDelete = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    const promises = deleted.map(async (id) => await deleteCompany(id));
    const results = await Promise.all(promises);

    if (results.length) {
      setResponse({
        error: results[0].error,
        message: results[0].message,
      });
    }

    setDisplay({
      loading: false,
      snackbar: true,
    });
  };

  const handleSubmit = async () => {
    let errorCount = 0;

    // owner for?
    company.owner = "6655832f244e22ee5c6f2f15";

    // required field
    const { company_name } = company;
    if (!company_name) {
      errors["company_name"] = true;
      errorCount++;
    }

    setErrors(errors);

    if (errorCount) return;

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    // submit form
    setDisplay({
      modal: false,
      loading: true,
    });

    const { error, message } = await addCompany(company);

    setResponse({
      error,
      message,
    });

    setDisplay({
      loading: false,
      snackbar: true,
    });

    // reset form
    handleCancel();
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const data = await getCompanies();

      const companies = data.map((company) => ({
        ...company,
        address: `${company.address_street}, ${company.address_city} ${company.address_zip}`,
        view: (
          <IconButton
            aria-label="View Account"
            component={Link}
            to={`/app/company/${company._id}`}
          >
            <Business />
          </IconButton>
        ),
        users: (
          <IconButton
            aria-label="Admins"
            component={Link}
            to={`/app/company-admins/${company._id}`}
          >
            <Group />
          </IconButton>
        ),
      }));

      setDisplay({
        loading: false,
      });
      setCompanies(companies);
    };

    if (!response.error) {
      setDisplay({
        loading: true,
      });
      fetchCompanies();
    }
  }, [response.error]);

  const options = {
    onRowsDelete: (rows) => {
      setDisplay({
        dialog: true,
      });
      const forDeletion = rows.data.map((row) => companies[row.index]["_id"]);
      setDeleted(forDeletion);
    },
  };

  return (
    <>
      <PageTitle
        title="Parent Company"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<Add />}
            onClick={handleOpen}
          >
            Add Company
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {display.loading ? (
            <EmproPaper />
          ) : (
            <MUIDataTable
              data={companies}
              options={options}
              columns={tblCompanyHdr}
            />
          )}
        </Grid>
        <EmproModal
          open={display.modal}
          onClose={handleCancel}
          ariaLabel="company-form"
          ariaDescription="company-form"
        >
          <h3 className={classes.header}>
            Name <Business className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="name"
                name="company_name"
                label="Business Name"
                value={company.company_name}
                required
                error={errors.company_name}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <h3 className={classes.header}>
            Address <LocationOn className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="street"
                name="address_street"
                label="Street"
                value={company.address_street}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="city"
                    name="address_city"
                    label="City"
                    value={company.address_city}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="zip"
                    name="address_zip"
                    label="Zip"
                    value={company.address_zip}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <h3 className={classes.header}>
            Contact <Person className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="person"
                name="company_contact_person"
                label="Name"
                value={company.company_contact_person}
                onChange={handleChange}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="email"
                    name="company_email"
                    label="Email"
                    type="email"
                    value={company.company_email}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="phone"
                    name="company_contact_number"
                    label="Phone"
                    value={company.company_contact_number}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Save />}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </EmproModal>
      </Grid>
      <EmproSnackbar
        display={display}
        onClose={handleClose}
        response={response}
      />
      <ConfirmDelete
        display={display}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </>
  );
}
