import { Grid } from "@material-ui/core";

// styles
import useStyles from "../styles";

// components
import Widget from "../../../components/Widget";
import OnboardingChart from "./Onboarding/Chart";
import OnboardingList from "./Onboarding/List";
import OnboardingPercent from "./Onboarding/Percent";

const OnboardingContainer = ({ data, title }) => {
  const classes = useStyles();
  return (
    <Widget title={title}>
      <div className={classes.visitsNumberContainer}>
        <Grid container item alignItems={"center"}>
          <Grid item md={4} xs={6}>
            <OnboardingChart data={data} />
          </Grid>
          <Grid item md={4} xs={6}>
            <OnboardingList data={data} />
          </Grid>
          <Grid item md={4} xs={6}>
            <OnboardingPercent data={data} />
          </Grid>
        </Grid>
      </div>
    </Widget>
  );
};

export default OnboardingContainer;
