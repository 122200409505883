import axios from "axios";

// axios config
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["x-access-token"] = sessionStorage.getItem(
  "id_token"
);

export const doLogin = async (email, pass) => {
  let res = [];

  const uri = "/user/login";
  const params = { username: email, password: pass };

  try {
    const { data } = await axios.post(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [doLogin]:", error);
  }
  return res;
};

export const updateUser = async (id, params) => {
  let res = [];

  const uri = `user/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateUser]:", error);
  }
  return res;
};

// for super user
export const getUsers = async () => {
  let res = [];
  try {
    // const { data } = await axios.get("user/list/all");
    const { data } = await axios.get("company/list/all/users");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUsers]:", error);
  }
  return res;
};

// for admin user
export const getUsersByCompany = async () => {
  let res = [];
  try {
    const { data } = await axios.get("user/list/all/company");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUsers]:", error);
  }
  return res;
};

export const getUser = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`user/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUser]:", error);
  }
  return res;
};

export const getUserActivity = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(
      `useractivity/list/filter/user?page=1&limit=5&user=${id}`
    );
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getUserActivity]:", error);
  }
  return res;
};

export const addUser = async (params) => {
  let uri = "";
  switch (params.role) {
    case 0:
      uri = "/user/register/super";
      break;
    case 2:
      uri = "/user/register/admin";
      break;
    default:
      uri = "/user/register";
      break;
  }

  try {
    const { data } = await axios.post(uri, params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteUser = async (id) => {
  try {
    const { data } = await axios.delete(`user/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const uploadVideo = async (id, params) => {
  try {
    const { data } = await axios.post(
      `company/upload/video/intro/${id}`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const uploadLogo = async (id, params) => {
  try {
    const { data } = await axios.post(
      `company/upload/image/logo/${id}`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const addCompany = async (params) => {
  try {
    const { data } = await axios.post("company/add", params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const updateCompany = async (id, params) => {
  let res = [];

  const uri = `company/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateCompany]:", error);
  }
  return res;
};

export const deleteCompany = async (id) => {
  try {
    const { data } = await axios.delete(`company/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getCompanies = async () => {
  let res = [];
  try {
    const { data } = await axios.get("company/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getCompanies]:", error);
  }
  return res;
};

export const getCompany = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`company/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getCompany]:", error);
  }
  return res;
};

export const getCompanyUsers = async () => {
  let res = [];
  try {
    const { data } = await axios.get(`userrole/list/company/all`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getCompanyUsers]:", error);
  }
  return res;
};

export const getOrganizations = async () => {
  let res = [];
  try {
    const { data } = await axios.get("organization/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getOrganizations]:", error);
  }
  return res;
};

export const addOrganization = async (params) => {
  // TODO: make dynamic
  params.owner = "66725cc7d3e3a4c65a532a3a";
  try {
    const { data } = await axios.post("organization/add", params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteOrganization = async (id) => {
  try {
    const { data } = await axios.delete(`organization/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getOrganization = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`organization/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getOrganization]:", error);
  }
  return res;
};

export const updateOrganization = async (id, params) => {
  let res = [];

  const uri = `organization/${id}`;
  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateOrganization]:", error);
  }
  return res;
};

export const getQuizzes = async () => {
  let res = [];
  try {
    const { data } = await axios.get("quiz/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuizzes]:", error);
  }
  return res;
};

export const getQuizDetails = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`quiz/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuizDetails]:", error);
  }
  return res;
};

export const addQuiz = async (params) => {
  try {
    const { data } = await axios.post("quiz/add", params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const updateQuiz = async (id, params) => {
  try {
    const { data } = await axios.put(`quiz/${id}`, params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteQuiz = async (id) => {
  try {
    const { data } = await axios.delete(`quiz/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getQuestionDetails = async (id) => {
  let res = [];
  try {
    const { data } = await axios.get(`question/${id}`);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuestionDetails]:", error);
  }
  return res;
};

export const getQuestions = async () => {
  let res = [];
  try {
    const { data } = await axios.get("question/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getQuestions]:", error);
  }
  return res;
};

export const addQuestion = async (params) => {
  const { quiz, question } = params;

  try {
    // 1. save all question choices and get the ids
    const optionIds = await Promise.all(
      question.options.map(async (option) => {
        const { data } = await addOption(option);
        return data._id;
      })
    );

    // 2. create question
    const params = {
      ...question,
      options: optionIds,
    };
    const { data } = await axios.post("question/add", params);

    // 3. update quiz with the new question
    const { _id } = data.data;
    const questions = [...quiz.questions, _id];
    await updateQuiz(quiz.id, { questions });

    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const addQuestionBackground = async (id, params) => {
  const uri = `question/upload/background/${id}`;
  try {
    const { data } = await axios.post(uri, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const updateOption = async (id, params) => {
  try {
    const { data } = await axios.put(`option/${id}`, params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const addOption = async (params) => {
  try {
    const { data } = await axios.post("option/add", params);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const getOption = async (id) => {
  try {
    const { data } = await axios.get(`option/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const deleteQuestion = async (id) => {
  try {
    const { data } = await axios.delete(`question/one/${id}`);
    return data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
};

export const updateQuestion = async (id, params) => {
  let res = [];

  const uri = `question/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data;
  } catch (e) {
    const { error, message } = e.response.data;
    return {
      error,
      message,
    };
  }
  return res;
};

export const getIdeas = async () => {
  let res = [];
  try {
    const { data } = await axios.get("ideashub/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getIdeas]:", error);
  }
  return res;
};

export const getIdeasByCompany = async () => {
  let res = [];
  try {
    const { data } = await axios.get("ideashub/list/company/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getIdeasByCompany]:", error);
  }
  return res;
};

export const updateIdea = async (id, params) => {
  let res = [];

  const uri = `ideashub/${id}`;

  try {
    const { data } = await axios.put(uri, params);
    res = data?.data;
  } catch (error) {
    console.error("ERROR [updateIdea]:", error);
  }
  return res;
};

export const getRoles = async () => {
  let res = [];
  try {
    const { data } = await axios.get("userrole/list/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getRoles]:", error);
  }
  return res;
};

export const getReporting = async () => {
  let res = [];
  try {
    const { data } = await axios.get("dashboard/report/all");
    res = data?.data;
  } catch (error) {
    console.error("ERROR [getReporting]:", error);
  }
  return res;
};

export const getRightToWork = async () => {};
export const getBankCheck = async () => {};

export const getLoginCount = ({ report }) => {
  let res = [];
  try {
    res = {
      head: {
        _id: "ID",
        first_name: "First Name",
        last_name: "Last Name",
        login_count: "Login Count",
      },
      data: report.top_user_logged_in,
    };
  } catch (error) {
    console.error("ERROR [getLoginCount]:", error);
  }
  return res;
};

export const getQuizCompleted = ({ report }) => {
  let res = [];
  try {
    res = {
      head: {
        _id: "ID",
        first_name: "First Name",
        last_name: "Last Name",
        quiz_attempt_count: "Quiz Completed",
      },
      data: report.top_quiz_attempt,
    };
  } catch (error) {
    console.error("ERROR [getQuizAttempts]:", error);
  }
  return res;
};

export const getVideoViews = ({ report }) => {
  let res = [];
  try {
    res = {
      head: {
        _id: "ID",
        first_name: "First Name",
        last_name: "Last Name",
        quiz_attempt_count: "Views Count",
      },
      data: report.video_view_count,
    };
  } catch (error) {
    console.error("ERROR [getVideoViews]:", error);
  }
  return res;
};
