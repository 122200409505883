// styles
import useStyles from "../../styles";

// components
import Dot from "../../../../components/Sidebar/components/Dot";
import { Typography } from "../../../../components/Wrappers/Wrappers";

const OnboardingList = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.pieChartLegendWrapper}>
      {data.map(({ name, value, color }, index) => (
        <div key={index} className={classes.legendItemContainer}>
          <Dot color={color} style={{ backgroundColor: color }} />
          <Typography style={{ whiteSpace: "nowrap", fontSize: 16 }}>
            &nbsp;{name}&nbsp;
          </Typography>
          <Typography
            color="text"
            colorBrightness="secondary"
            style={{ fontSize: 16 }}
            weight="bold"
          >
            &nbsp;{value}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default OnboardingList;
