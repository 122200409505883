export const USER_STATUS = [
  {
    name: "Deactive",
    value: 0,
  },
  {
    name: "Active",
    value: 1,
  },
];
