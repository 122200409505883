// styles
import useStyles from "../../styles";

// components
import Dot from "../../../../components/Sidebar/components/Dot";
import { Typography } from "../../../../components/Wrappers/Wrappers";

const EngagementList = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.pieChartLegendWrapper}>
      {data.map(({ name, value, color }, index) => (
        <div key={index} className={classes.legendItemContainer}>
          <Dot color={color} />
          <Typography
            style={{
              whiteSpace: "nowrap",
              fontSize: 12,
              textTransform: "capitalize",
            }}
          >
            &nbsp;{name}&nbsp;
          </Typography>
          <Typography color="text" colorBrightness="secondary">
            &nbsp;{value}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default EngagementList;
