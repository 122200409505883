export const iUser = {
  first_name: "",
  last_name: "",
  role: "",
  company: "",
  organization: "",
  email: "",
  password: "",
};

export const iCompany = {
  company_name: "",
  company_address: "",
  address_street: "",
  address_city: "",
  address_zip: "",
  company_contact_person: "",
  company_email: "",
  company_contact_number: "",
  company_logo: "",
  primary_color: "",
  secondary_color: "",
  tertiary_color: "",
  accent_color: "",
  overlay_color: "",
  neutral_color: "",

  //   name: "",
  //   address: {
  //     street: "",
  //     city: "",
  //     zip: "",
  //   },
  //   contact: {
  //     email: "",
  //     phone: "",
  //   },
  //   settings: {
  //     logo: "",
  //     primary: "",
  //     secondary: "",
  //   },
};

export const iOrganization = {
  app_name: "",
  organization_name: "",
  organization_logo: "",
  organization_address: "",
  address_street: "",
  address_city: "",
  address_zip: "",
  organization_email: "",
  organization_contact_number: "",
  organization_contact_person: "",
};

export const iQuiz = {
  title: "",
  company: "",
  questions: [],
};

export const iQuestion = {
  background: "",
  company: "",
  text: "",
  options: [],
  correct_index: 0,
  status: 0,
  right_answer_prompt: "",
  wrong_answer_prompt: "",
};

export const iUserErrors = {
  first_name: false,
  last_name: false,
  company: false,
  role: false,
  email: false,
  password: false,
};

export const iDisplay = {
  dialog: false,
  loading: false,
  modal: false,
  snackbar: false,
};

export const iIdeas = {
  id: "",
  action: "",
  review_notes: "",
};

export const iResponse = {
  error: "",
  message: "",
};

export const iQuestionErrors = {
  answer: false,
  text: false,
  choice1: false,
  choice2: false,
  choice3: false,
  choice4: false,
  right_answer_prompt: false,
  wrong_answer_prompt: false,
};
