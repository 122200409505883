import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  OutlinedInput,
  MenuItem,
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { useJsonToCsv } from "react-json-csv";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

// data
import {
  getReporting,
  getRightToWorkInactive,
  getRightToWorkActive,
  getBankCheckerActive,
  getBankCheckerInactive,
  getLoginCount,
  getQuizCompleted,
  getVideoViews,
} from "../../hooks/useRequest";
import { useUserState } from "../../context/UserContext";

// helper
import { isSuper, reports } from "../../helpers/utils";

export default function Reports(props) {
  const classes = useStyles();
  const {
    userData: { company, role },
  } = useUserState();

  const theme = useTheme();
  const { saveAsCsv } = useJsonToCsv();

  const [selected, setSelected] = useState([]);
  const [companyID, setCompanyID] = useState("");
  const [companies, setCompanies] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const getDownload = async (key) => {
    let csvHead = {},
      csvData = [];
    switch (key) {
      case 0:
        console.log("Fetching Right to Work Active data...");
        const rightToWorkActive = await getRightToWorkActive();
        console.log("Right to Work Active Head:", rightToWorkActive.head);
        console.log("Right to Work Active Data:", rightToWorkActive.data);
        csvHead = rightToWorkActive.head;
        csvData = rightToWorkActive.data || [];
        break;
      
      case 1:
        console.log("Fetching Right to Work Inactive data...");
        const rightToWorkInactive = await getRightToWorkInactive();
        console.log("Right to Work Inactive Head:", rightToWorkInactive.head);
        console.log("Right to Work Inactive Data:", rightToWorkInactive.data);
        csvHead = rightToWorkInactive.head;
        csvData = rightToWorkInactive.data || [];
        break;
      
      case 2:
        console.log("Fetching Bank Check Verified data...");
        const bankCheckVerified = await getBankCheckerActive();
        console.log("Bank Check Verified Head:", bankCheckVerified.head);
        console.log("Bank Check Verified Data:", bankCheckVerified.data);
        csvHead = bankCheckVerified.head;
        csvData = bankCheckVerified.data || [];
        break;
      
      case 3:
        console.log("Fetching Bank Check Unverified data...");
        const bankCheckUnverified = await getBankCheckerInactive();
        console.log("Bank Check Unverified Head:", bankCheckUnverified.head);
        console.log("Bank Check Unverified Data:", bankCheckUnverified.data);
        csvHead = bankCheckUnverified.head;
        csvData = bankCheckUnverified.data || [];
        break;
      case 4:
        const login = getLoginCount(companyData);
        csvHead = login.head;
        csvData = login.data || [];
        break;
      case 5:
        const quiz = getQuizCompleted(companyData);
        csvHead = quiz.head;
        csvData = quiz.data || [];
        break;
      case 6:
        const video = getVideoViews(companyData);
        csvHead = video.head;
        csvData = video.data || [];
        break;

      default:
        break;
    }

    return {
      csvHead,
      csvData,
    };
  };

  const handleDownload = async () => {
    console.log(companyData.company_name, "===>", selected);

    for (const e of selected) {
        console.log(`Fetching data for report: ${reports[e]}`);
        const { csvHead, csvData } = await getDownload(e); // Ensure we await the async function
        console.log(`Data received for report: ${reports[e]}`, csvHead, csvData);

        saveAsCsv({
            data: csvData,
            fields: csvHead,
            filename: `${companyData.company_name} - ${reports[e]}`,
        });
    }
};


  const handleChange = (val) => {
    const companyData = companies.find(({ _id }) => _id === val);

    setCompanyID(val);
    setCompanyData(companyData);
    setSelected([]);
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelected(newChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getReporting();
      setCompanies(data);

      // set data base on role
      const companyID = isSuper(role) ? data[0]._id : company._id;
      const companyData = data.find(({ _id }) => _id === companyID);
      setCompanyID(companyID);
      setCompanyData(companyData);
    };

    fetchData();
  }, [company._id, role]);

  return (
    <>
      <PageTitle
        title="Reports"
        button={
          // show company seletion to super user only
          isSuper(role) && (
            <Select
              value={companyID}
              onChange={(e) => handleChange(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: classes.engagementSelectRoot,
                    input: classes.engagementSelect,
                  }}
                />
              }
              autoWidth
            >
              {companies.map(({ _id, company_name }, i) => (
                <MenuItem value={_id} key={i}>
                  {company_name}
                </MenuItem>
              ))}
            </Select>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item md={12} sm={12} xs={12}>
          <Widget>
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item md={8} xs={8}>
                  <List className={classes.root}>
                    {reports.map((label, index) => (
                      <ListItem
                        key={index}
                        dense
                        button
                        onClick={handleToggle(index)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selected.indexOf(index) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": `report-${label}`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText id={`report-${label}`} primary={label} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item md={4} xs={4}>
                  {selected.length > 0 && (
                    <div className={classes.download}>
                      <IconButton
                        size="medium"
                        aria-label="download"
                        onClick={handleDownload}
                      >
                        <CloudDownload className={classes.downloadIcon} />
                      </IconButton>
                      <Typography
                        variant="overline"
                        display="block"
                        gutterBottom
                      >
                        Download
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
