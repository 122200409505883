import { useEffect, useState, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Add, Assignment, QuestionAnswer, Save } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import EmproPaper from "../../components/Loading/Paper";
import EmproModal from "../../components/Modal";
import EmproSnackbar from "../../components/Snackbar";
import { ConfirmDelete } from "../../components/Dialog";

// data
import {
  addQuiz,
  deleteQuiz,
  getQuizzes,
  getCompanies,
} from "../../hooks/useRequest";

// helpers
import { tblQuizHdr } from "../../helpers/utils";
import { iQuiz, iDisplay } from "../../helpers/props";

// styles
import useStyles from "./styles";

export default function Quiz() {
  const classes = useStyles();

  const [quiz, setQuiz] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iQuiz
  );
  const [errors, setErrors] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { title: "", company: "" }
  );
  const [response, setResponse] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { error: "", message: "" }
  );
  const [display, setDisplay] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    iDisplay
  );
  const [quizzes, setQuizzes] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [companies, setCompanies] = useState([]);

  const resetErrors = () =>
    Object.keys(errors).forEach((key) => (errors[key] = false));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuiz({
      [name]: value,
    });
  };

  const handleOpen = () => {
    setDisplay({
      modal: true,
    });
  };

  const handleDelete = async () => {
    setDisplay({
      dialog: false,
      loading: true,
    });

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    const promises = deleted.map(async (id) => await deleteQuiz(id));
    const results = await Promise.all(promises);

    if (results.length) {
      setResponse({
        error: results[0].error,
        message: results[0].message,
      });
    }

    setDisplay({
      loading: false,
      snackbar: true,
    });
  };

  const handleSubmit = async () => {
    let errorCount = 0;
    resetErrors();

    // required field
    const { title, company } = quiz;
    if (!title) {
      errors["title"] = true;
      errorCount++;
    }

    if (!company) {
      errors["company"] = true;
      errorCount++;
    }

    setErrors(errors);

    if (errorCount) return;

    // reset error response
    setResponse({
      error: "",
      message: "",
    });

    // submit form
    setDisplay({
      modal: false,
      loading: true,
    });

    const { error, message } = await addQuiz(quiz);

    setResponse({
      error,
      message,
    });

    setDisplay({
      loading: false,
      snackbar: true,
    });

    // reset form
    handleCancel();
  };

  const handleCancel = () => {
    // reset form
    resetErrors();
    Object.keys(quiz).forEach((key) => (quiz[key] = ""));

    setQuiz(quiz);
    setErrors(errors);
    setDisplay({
      modal: false,
    });
  };

  const handleCloseDialog = () => {
    setDisplay({
      dialog: false,
    });
  };

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDisplay({
      snackbar: false,
    });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companies = await getCompanies();
      setCompanies(companies);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchQuiz = async () => {
      const data = await getQuizzes();
      const quizzes = data.map((quiz) => ({
        ...quiz,
        company: quiz?.company.company_name,
        status: quiz.status ? "Active" : "Inactive",
        view: (
          <IconButton
            aria-label="View"
            component={Link}
            to={`/app/quiz/${quiz._id}`}
          >
            <QuestionAnswer />
          </IconButton>
        ),
      }));

      if (!response.error) {
        setDisplay({
          loading: false,
        });
        setQuizzes(quizzes);
      }
    };

    setDisplay({
      loading: true,
    });
    fetchQuiz();
  }, [response.error]);

  const options = {
    onRowsDelete: (rows) => {
      setDisplay({
        dialog: true,
      });
      const forDeletion = rows.data.map((row) => quizzes[row.index]["_id"]);
      setDeleted(forDeletion);
    },
  };

  return (
    <>
      <PageTitle
        title="Quizzes"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<Add />}
            onClick={handleOpen}
          >
            Create Quiz
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {display.loading ? (
            <EmproPaper />
          ) : (
            <MUIDataTable
              data={quizzes}
              columns={tblQuizHdr}
              options={options}
            />
          )}
        </Grid>
        <EmproModal
          open={display.modal}
          onClose={handleCancel}
          ariaLabel="quiz-form"
          ariaDescription="quiz-form"
        >
          <h3 className={classes.header}>
            Create Quiz <Assignment className={classes.iconStyle} />
          </h3>
          <div className={classes.mb5}>
            <FormControl className={classes.formControl}>
              <TextField
                id="title"
                name="title"
                label="Quiz Title"
                value={quiz.title}
                required
                error={errors.title}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl
              className={classes.formControl}
              required
              error={errors.company}
            >
              <InputLabel id="company-label">Company</InputLabel>
              <Select
                labelId="company-label"
                id="company"
                name="company"
                value={quiz.company}
                onChange={handleChange}
              >
                {companies.map(({ _id, company_name }, key) => (
                  <MenuItem value={_id} key={key}>
                    {company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.footer}>
            <Button variant="contained" size="medium" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={<Save />}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </EmproModal>
      </Grid>
      <EmproSnackbar
        display={display}
        onClose={handleClose}
        response={response}
      />
      <ConfirmDelete
        display={display}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </>
  );
}
