// styles
import useStyles from "../../styles";

const getPercent = (partial, total) => {
  return parseFloat((partial / total) * 100).toFixed(0);
};

const OnboardingPercent = ({ data }) => {
  const classes = useStyles();
  const color = data[0]?.color;
  return (
    <div className={classes.percentBox}>
      <div className={classes.percentValue} style={{ color: color }}>
        {getPercent(data[0]?.value, data[0]?.value + data[1]?.value)}%
      </div>
      <div className={classes.percentTitle}>Completed</div>
    </div>
  );
};

export default OnboardingPercent;
