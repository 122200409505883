import React, { useEffect, useState } from "react";
import { Grid, Select, OutlinedInput, MenuItem } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";

import EngagementContainer from "./components/Engagement";
import OnboardingContainer from "./components/Onboarding";

// data
import { getReporting } from "../../hooks/useRequest";
import { useUserState } from "../../context/UserContext";

// helpers
import { isSuper } from "../../helpers/utils";

const colors = [
  // 'primary',
  // 'secondary',
  // 'tertiary',
  // 'neutral',
  // 'overlay',
  // 'accent',
  // 'success',
  // 'info',
  // 'warning',
  // 'danger',
  "purple",
  "indigo",
  "pink",
  "blue",
  "cyan",
  "teal",
  "green",
  "lime",
  "yellow",
  "orange",
];

export default function Dashboard(props) {
  const classes = useStyles();
  const {
    userData: { company, role },
  } = useUserState();

  const [reports, setReports] = useState([]);
  const [companyID, setCompanyID] = useState("");
  const [companies, setCompanies] = useState([]);

  const [rightToWork, setRightToWork] = useState([]);
  const [bankCheck, setBankCheck] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [quizData, setQuizData] = useState([]);

  const setData = (data, id) => {
    const { report } = data.find(({ _id }) => _id === id);

    const logins = report?.top_user_logged_in?.length
      ? report.top_user_logged_in.map((data, key) => ({
          name: `${data.first_name} ${data.last_name}`,
          value: data.login_count,
          color: colors[key],
        }))
      : [];

    const videos = report?.top_user_video_view?.length
      ? report.top_user_video_view.map((data, key) => ({
          name: `${data.first_name} ${data.last_name}`,
          value: data.video_view_count,
          color: colors[key],
        }))
      : [];

    const quiz = report?.top_quiz_attempt?.length
      ? report.top_quiz_attempt.map((data, key) => ({
          name: `${data.first_name} ${data.last_name}`,
          value: data.quiz_attempt_count,
          color: colors[key],
        }))
      : [];

    setLoginData(logins);
    setVideoData(videos);
    setQuizData(quiz);
    setRightToWork([
      {
        name: "Verified",
        value: report.right_to_work_active,
        color: company.primary_color,
      },
      {
        name: "Unverified",
        value: report.right_to_work_inactive,
        color: company.secondary_color,
      },
    ]);
    setBankCheck([
      {
        name: "Verified",
        value: report.verified_account,
        color: company.primary_color,
      },
      {
        name: "Unverified",
        value: report.not_verified_account,
        color: company.secondary_color,
      },
    ]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getReporting();
      const companies = data.map(({ _id, company_name }) => ({
        id: _id,
        name: company_name,
      }));

      setReports(data);
      setCompanies(companies);

      // set data base on role
      const companyID = isSuper(role) ? companies[0].id : company._id;
      setCompanyID(companyID);
      setData(data, companyID);
    };

    fetchData();
  }, []);

  const handleChange = (val) => {
    setCompanyID(val);
    setLoginData([]);
    setRightToWork([]);
    setBankCheck([]);

    setData(reports, val);
  };

  return (
    <>
      <PageTitle
        title="Onboarding"
        button={
          // show company seletion to super user only
          isSuper(role) && (
            <Select
              value={companyID}
              onChange={(e) => handleChange(e.target.value)}
              input={
                <OutlinedInput
                  labelWidth={0}
                  classes={{
                    notchedOutline: classes.engagementSelectRoot,
                    input: classes.engagementSelect,
                  }}
                />
              }
              autoWidth
            >
              {companies.map(({ id, name }, i) => (
                <MenuItem value={id} key={i}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item md={6} sm={6} xs={12}>
          <OnboardingContainer data={rightToWork} title="Right To Work" />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <OnboardingContainer data={bankCheck} title="Bank Check" />
        </Grid>
      </Grid>
      <PageTitle
        title="Engagement"
        // button={
        //   <Select
        //     value={"monthly"}
        //     onChange={(e) => console.log("VALUE :===>", e.target.value)}
        //     input={
        //       <OutlinedInput
        //         labelWidth={0}
        //         classes={{
        //           notchedOutline: classes.engagementSelectRoot,
        //           input: classes.engagementSelect,
        //         }}
        //       />
        //     }
        //     autoWidth
        //   >
        //     <MenuItem value="daily">Daily</MenuItem>
        //     <MenuItem value="weekly">Weekly</MenuItem>
        //     <MenuItem value="monthly">Monthly</MenuItem>
        //   </Select>
        // }
      />
      <Grid container spacing={2}>
        <Grid item md={4} sm={12}>
          <EngagementContainer data={loginData} title="Top User Logins" />
        </Grid>
        <Grid item md={4} sm={12}>
          <EngagementContainer data={quizData} title="Top Quiz Completed" />
        </Grid>
        <Grid item md={4} sm={12}>
          <EngagementContainer data={videoData} title="Top Video Views" />
        </Grid>
      </Grid>
    </>
  );
}
